import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Box, Typography } from '@mui/material';

const LoadingFallback: React.FC = () => {
  const { t } = useTranslation();
  
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
      <CircularProgress />
      <Typography variant="body2" mt={2}>{t('common.loading')}</Typography>
    </Box>
  );
};

export default LoadingFallback;