import React, { createContext, useContext, useEffect, useState } from 'react';
import type { TelegramUser } from '../types/telegram';

interface TelegramContextType {
  isTelegram: boolean;
  webApp: typeof window.Telegram.WebApp | null;
  user: TelegramUser | null;
  isInitialized: boolean;
}

const TelegramContext = createContext<TelegramContextType>({
  isTelegram: false,
  webApp: null,
  user: null,
  isInitialized: false
});

export const useTelegram = () => useContext(TelegramContext);

export const TelegramProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isTelegram, setIsTelegram] = useState(false);
  const [webApp, setWebApp] = useState<typeof window.Telegram.WebApp | null>(null);
  const [user, setUser] = useState<TelegramUser | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    // Функция инициализации Telegram
    const initTelegram = () => {
      if (window.Telegram?.WebApp) {
        setIsTelegram(true);
        setWebApp(window.Telegram.WebApp);
        
        if (window.Telegram.WebApp.initDataUnsafe?.user) {
          setUser(window.Telegram.WebApp.initDataUnsafe.user);
        }

        window.Telegram.WebApp.ready();
        window.Telegram.WebApp.expand();
        
        // Устанавливаем цвета в соответствии с вашей темой
        window.Telegram.WebApp.setHeaderColor('#0071e2');
        window.Telegram.WebApp.setBackgroundColor('#ffffff');
      }
    };

    if (window.location.search.includes('tgWebAppStartParam') || window.location.hash.includes('tgWebAppStartParam')) {
      if (!window.Telegram?.WebApp) {
        // Создаем элемент script
        const script = document.createElement('script');
        script.src = 'https://telegram.org/js/telegram-web-app.js';
        script.async = true;
        
        // Когда скрипт загрузится, инициализируем Telegram
        script.onload = initTelegram;
        
        // Добавляем скрипт в head
        document.head.appendChild(script);
      } else {
        // Если скрипт уже загружен, просто инициализируем
        initTelegram();
      }
    } else {
      setIsInitialized(true);
    }

    // Очистка при размонтировании
    return () => {
      if (webApp) {
        // Очищаем все обработчики событий при размонтировании
        webApp.offEvent('viewportChanged', () => {});
        webApp.MainButton.offClick(() => {});
        webApp.BackButton.offClick(() => {});
      }
    };
  }, []);

  return (
    <TelegramContext.Provider value={{ isTelegram, webApp, user, isInitialized }}>
      {children}
    </TelegramContext.Provider>
  );
};