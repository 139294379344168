import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  TextField, 
  Card, 
  CardContent, 
  Grid, 
  Chip,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  CircularProgress,
  Link
} from '@mui/material';
import { 
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
  Flight as FlightIcon,
  Pets as PetsIcon,
  AttachMoney as PriceIcon,
  Warning as WarningIcon,
  OpenInNew as OpenInNewIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

// Используем интерфейс из types.ts
interface Airline {
  AirlineID: number;
  Name: string;
  AirlineCode: string;
  Phones: string;
  IsMustPetPhoneApprove: boolean;
  AirlineInfo: string;
  AllowedPets: string;
  AllowedCarriers: string;
  TicketClass: string | null;
  IsPetSeatService: boolean;
  AllowedAircrafts: string;
  RestrictionsDescription: string;
  MaxAnimalsOnBoard: number | null;
  MaxAnimalsPerPassenger: number | null;
  MaxWeightCabin: number | null;
  MaxWeightBaggage: number | null;
  IsAllowedInCabin: boolean;
  IsAllowedInBaggage: boolean;
  ProhibitedBreeds: string | null;
  BrachycephalicStop: string | null;
  MultipleAnimalsPolicy: string | null;
  TransitPolicy: string | null;
  DomesticCabinPetCost: number | null;
  InternationalCabinPetCost: number | null;
  DomesticBaggagePetCost: number | null;
  InternationalBaggagePetCost: number | null;
  PetCostCalculationType: 'fixed' | 'weight-based' | 'fare_percent' | 'baggage_based';
  CurrencyCode: string;
  CountryID: number;
  AirlineWebsite: string;
}

// Добавим enum или объект с типами животных
const PET_TYPES: Record<string, string> = {
  '1': 'dogs',
  '2': 'cats',
  '3': 'birds',
  '4': 'rodents',
  '5': 'rabbits',
  '6': 'ferrets',
  '9': 'other'
};

const AirlineRules: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [airlines, setAirlines] = useState<Airline[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedAirline, setSelectedAirline] = useState<Airline | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAirlines();
  }, []);

  const fetchAirlines = async () => {
    try {
      console.log('Fetching airlines from:', process.env.REACT_APP_GRAPHQL_URI);
      const response = await fetch(`${process.env.REACT_APP_GRAPHQL_URI}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
            query {
              airlines {
                AirlineID
                Name
                AirlineCode
                Phones
                IsMustPetPhoneApprove
                AirlineInfo
                AllowedPets
                AllowedCarriers
                TicketClass
                IsPetSeatService
                AllowedAircrafts
                RestrictionsDescription
                MaxAnimalsOnBoard
                MaxAnimalsPerPassenger
                MaxWeightCabin
                MaxWeightBaggage
                IsAllowedInCabin
                IsAllowedInBaggage
                ProhibitedBreeds
                BrachycephalicStop
                MultipleAnimalsPolicy
                TransitPolicy
                DomesticCabinPetCost
                InternationalCabinPetCost
                DomesticBaggagePetCost
                InternationalBaggagePetCost
                PetCostCalculationType
                CurrencyCode
                CountryID
                AirlineWebsite
                Country {
                  CountryID
                  CountryName
                  alpha3Code
                }
              }
            }
          `
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('GraphQL response error:', errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { data, errors } = await response.json();
      
      if (errors) {
        console.error('GraphQL Errors:', errors);
        throw new Error('GraphQL query failed');
      }

      if (data && data.airlines) {
        console.log('Received airlines:', data.airlines.length);
        setAirlines(data.airlines);
      } else {
        console.error('No airlines data in response');
      }
    } catch (error) {
      console.error('Error fetching airlines:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredAirlines = airlines.filter(airline => 
    airline.Name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    airline.AirlineCode.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Функция для получения чипов с типами животных
  const getAllowedPetsChips = (allowedPets: string) => {
    if (!allowedPets) return null;
    
    const petTypes = allowedPets.split(',').map(type => type.trim());
    return petTypes.map((type, index) => {
      const petTypeKey = PET_TYPES[type] || 'unknown';
      const label = t(`pets.types.${petTypeKey}`);
      
      return (
        <Chip 
          key={`${type}-${index}`}
          icon={<PetsIcon />}
          label={label}
          size="small"
          color="primary"
          sx={{ mr: 0.5, mb: 0.5 }}
        />
      );
    });
  };

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder={t('guide.airlines.searchPlaceholder')}
          InputProps={{
            startAdornment: <SearchIcon sx={{ color: 'text.secondary', mr: 1 }} />,
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ backgroundColor: 'background.paper', borderRadius: 1 }}
        />
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {filteredAirlines.map((airline) => (
            <Grid item xs={12} sm={6} md={4} key={airline.AirlineID}>
              <Card 
                onClick={() => setSelectedAirline(airline)}
                sx={{ 
                  cursor: 'pointer',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'translateY(-4px)',
                    boxShadow: theme.shadows[4],
                  }
                }}
              >
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">
                      {airline.Name} ({airline.AirlineCode})
                    </Typography>
                  </Box>
                  <Box sx={{ mb: 2 }}>
                    {getAllowedPetsChips(airline.AllowedPets)}
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                    {airline.IsAllowedInCabin && (
                      <Chip 
                        icon={<PetsIcon />}
                        label={t('guide.airlines.cabinAllowed')}
                        color="success"
                        size="small"
                      />
                    )}
                    {airline.IsAllowedInBaggage && (
                      <Chip 
                        icon={<FlightIcon />}
                        label={t('guide.airlines.BaggageAllowed')}
                        color="info"
                        size="small"
                      />
                    )}
                    {airline.IsMustPetPhoneApprove && (
                      <Chip 
                        icon={<WarningIcon />}
                        label={t('guide.airlines.phoneApprovalRequired')}
                        color="warning"
                        size="small"
                      />
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Dialog 
        open={!!selectedAirline} 
        onClose={() => setSelectedAirline(null)}
        maxWidth="md"
        fullWidth
      >
        {selectedAirline && (
          <>
            <DialogTitle>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Typography component="div" variant="h5">
                  {selectedAirline.Name} ({selectedAirline.AirlineCode})
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">{t('guide.airlines.generalInfo')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1" paragraph>
                    {selectedAirline.AirlineInfo}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      {selectedAirline.MaxWeightCabin && (
                        <Typography variant="body2">
                          {t('guide.airlines.maxWeightCabin')}: {selectedAirline.MaxWeightCabin} kg
                        </Typography>
                      )}
                      {selectedAirline.MaxWeightBaggage && (
                        <Typography variant="body2">
                          {t('guide.airlines.maxWeightBaggage')}: {selectedAirline.MaxWeightBaggage} kg
                        </Typography>
                      )}
                      {selectedAirline.MaxAnimalsOnBoard && (
                        <Typography variant="body2">
                          {t('guide.airlines.maxAnimalsOnBoard')}: {selectedAirline.MaxAnimalsOnBoard}
                        </Typography>
                      )}
                      {selectedAirline.MaxAnimalsPerPassenger && (
                        <Typography variant="body2">
                          {t('guide.airlines.maxAnimalsPerPassenger')}: {selectedAirline.MaxAnimalsPerPassenger}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {selectedAirline.Phones && (
                        <Typography variant="body2">
                          {t('guide.airlines.contacts')}: {selectedAirline.Phones}
                        </Typography>
                      )}
                      {selectedAirline.AirlineWebsite && (
                        <Typography variant="body2">
                          <Link 
                            href={selectedAirline.AirlineWebsite} 
                            target="_blank" 
                            rel="noopener noreferrer"
                            sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}
                          >
                            {t('guide.airlines.website')}
                            <OpenInNewIcon fontSize="small" />
                          </Link>
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">{t('guide.airlines.restrictions')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {selectedAirline.RestrictionsDescription && (
                    <Typography variant="body2" paragraph>
                      {selectedAirline.RestrictionsDescription}
                    </Typography>
                  )}
                  {selectedAirline.ProhibitedBreeds && (
                    <Typography variant="body2" paragraph>
                      <strong>{t('guide.airlines.prohibitedBreeds')}:</strong> {selectedAirline.ProhibitedBreeds}
                    </Typography>
                  )}
                  {selectedAirline.BrachycephalicStop && (
                    <Typography variant="body2" paragraph>
                      <strong>{t('guide.airlines.brachycephalicPolicy')}:</strong> {selectedAirline.BrachycephalicStop}
                    </Typography>
                  )}
                  {selectedAirline.MultipleAnimalsPolicy && (
                    <Typography variant="body2" paragraph>
                      <strong>{t('guide.airlines.multipleAnimalsPolicy')}:</strong> {selectedAirline.MultipleAnimalsPolicy}
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">{t('guide.airlines.costs')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {selectedAirline.DomesticCabinPetCost && (
                      <Grid item xs={12} md={6}>
                        <Typography variant="body2">
                          {t('guide.airlines.domesticCabinCost')}: 
                          {selectedAirline.DomesticCabinPetCost} {selectedAirline.CurrencyCode}
                        </Typography>
                      </Grid>
                    )}
                    {selectedAirline.InternationalCabinPetCost && (
                      <Grid item xs={12} md={6}>
                        <Typography variant="body2">
                          {t('guide.airlines.internationalCabinCost')}: 
                          {selectedAirline.InternationalCabinPetCost} {selectedAirline.CurrencyCode}
                        </Typography>
                      </Grid>
                    )}
                    {selectedAirline.DomesticBaggagePetCost && (
                      <Grid item xs={12} md={6}>
                        <Typography variant="body2">
                          {t('guide.airlines.domesticBaggageCost')}: 
                          {selectedAirline.DomesticBaggagePetCost} {selectedAirline.CurrencyCode}
                        </Typography>
                      </Grid>
                    )}
                    {selectedAirline.InternationalBaggagePetCost && (
                      <Grid item xs={12} md={6}>
                        <Typography variant="body2">
                          {t('guide.airlines.internationalBaggageCost')}: 
                          {selectedAirline.InternationalBaggagePetCost} {selectedAirline.CurrencyCode}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </DialogContent>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default AirlineRules; 