import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

export type Currency = 'EUR' | 'USD' | 'RUB';

type ExchangeRates = {
  [key in Currency]: {
    [key in Currency]?: number;
  };
};

interface CurrencyContextType {
  currency: Currency;
  setCurrency: (currency: Currency) => void;
  convertPrice: (price: number, fromCurrency: Currency, toCurrency: Currency) => number;
  formatPrice: (price: number, currency: Currency) => string;
}

const CurrencyContext = createContext<CurrencyContextType | undefined>(undefined);

export const CurrencyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currency, setCurrency] = useState<Currency>('EUR');
  const [exchangeRates, setExchangeRates] = useState<ExchangeRates>({
    EUR: { USD: 1.1, RUB: 90 },
    USD: { EUR: 1 / 1.1, RUB: 80 },
    RUB: { EUR: 1 / 90, USD: 1 / 80 }
  });

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await fetch('https://api.exchangerate-api.com/v4/latest/EUR');
        const data = await response.json();
        setExchangeRates({
          EUR: { USD: data.rates.USD, RUB: data.rates.RUB },
          USD: { EUR: 1 / data.rates.USD, RUB: data.rates.RUB / data.rates.USD },
          RUB: { EUR: 1 / data.rates.RUB, USD: data.rates.USD / data.rates.RUB }
        });
      } catch (error) {
        console.error('Failed to fetch exchange rates:', error);
      }
    };

    fetchExchangeRates();
  }, []);

  const convertPrice = useCallback((price: number, fromCurrency: Currency, toCurrency: Currency): number => {
    if (fromCurrency === toCurrency) return price;
    const rate = exchangeRates[fromCurrency][toCurrency];
    if (typeof rate === 'undefined') {
      console.error(`Exchange rate not found for ${fromCurrency} to ${toCurrency}`);
      return price;
    }
    return price * rate;
  }, [exchangeRates]);

  const formatPrice = useCallback((price: number, currency: Currency): string => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return formatter.format(price);
  }, []);

  return (
    <CurrencyContext.Provider value={{ currency, setCurrency, convertPrice, formatPrice }}>
      {children}
    </CurrencyContext.Provider>
  );
};

export const useCurrency = () => {
  const context = useContext(CurrencyContext);
  if (context === undefined) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }
  return context;
};
