import React from "react";
import { Typography, Container, Link } from "@mui/material"; // Заменен импорт на @mui/material
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <Container maxWidth="sm">
        <Typography variant="body2" color="textSecondary" align="center" sx={{ fontFamily: '"Nunito", sans-serif', fontWeight: 600 }}>
          {t("footer.copyright")} © {new Date().getFullYear()}{" "}
          <Link color="inherit" href="https://avia.pet/">
            AVIAPET
          </Link>
        </Typography>
      </Container>
    </footer>
  );
};

export default Footer;
