import React from 'react';
import { BottomNavigation, BottomNavigationAction, Paper, Tooltip } from '@mui/material';
import { Home as HomeIcon, FlightTakeoff as FlightIcon, EventNote as EventNoteIcon, Pets as PetsIcon, AccountCircle as AccountCircleIcon, MenuBook as GuideIcon } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { logEvent, AmplitudeEvents } from '../utils/amplitude';

const StyledBottomNavigationAction = styled(BottomNavigationAction)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fontSize: '1.5rem',
    color: '#ffffff',
    marginBottom: '4px',
    transition: 'all 0.3s ease',
  },
  '& .MuiBottomNavigationAction-label': {
    fontSize: '0.75rem',
    color: '#ffffff',
    transition: 'all 0.3s ease',
  },
  padding: '4px 0 6px',
  minWidth: '50px',
  flex: '1 1 auto',
  '&.Mui-selected': {
    '& .MuiSvgIcon-root': {
      transform: 'scale(1.1)',
      filter: 'drop-shadow(0 0 8px rgba(255, 255, 255, 0.7))',
    },
    '& .MuiBottomNavigationAction-label': {
      fontSize: '0.8rem',
      fontWeight: 600,
      textShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
    },
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: '20%',
      right: '20%',
      height: '2px',
      background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.8), transparent)',
      borderRadius: '3px 3px 0 0',
      boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
    }
  },
}));

const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  backgroundColor: 'transparent',
  '& .MuiBottomNavigationAction-root': {
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(255, 255, 255, 0.1)',
      borderRadius: '16px',
      opacity: 0,
      transition: 'opacity 0.3s ease',
    },
    '&:hover::before': {
      opacity: 1,
    },
    '&.Mui-selected::before': {
      opacity: 0.15,
      background: 'rgba(255, 255, 255, 0.2)',
    }
  }
}));

const BottomNav: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  // Функция для определения текущего значения на основе пути
  const getCurrentValue = (pathname: string) => {
    switch (pathname) {
      case '/':
        return 0; // Home
      case '/search':
        return 1; // Flights
      case '/bookings':
        return 2; // Bookings
      case '/guide':
        return 3; // Guide
      case '/pets':
        return 4; // Pets
      case '/profile':
        return 5; // Profile
      default:
        return 0;
    }
  };

  const handleNavigation = (path: string) => {
    // Логируем событие навигации
    logEvent(AmplitudeEvents.NAVIGATION_CLICK, {
      from: location.pathname,
      to: path,
      source: 'bottom_nav'
    });
    navigate(path);
  };

  return (
    <Paper 
      sx={{ 
        position: 'fixed', 
        bottom: 0, 
        left: 0, 
        right: 0, 
        zIndex: 1000, 
        paddingTop: '10px', 
        paddingBottom: '10px',
        background: 'linear-gradient(45deg, rgba(0, 113, 226, 0.85), rgba(0, 166, 255, 0.70))',
        backdropFilter: 'blur(8px)',
        borderRadius: '40px 40px 0 0',
        boxShadow: '0 -4px 20px rgba(100, 149, 237, 0.2)',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '40px 40px 0 0',
          backdropFilter: 'blur(5px)',
        }
      }} 
      elevation={0}
    >
      <StyledBottomNavigation
        value={getCurrentValue(location.pathname)}
        showLabels
        sx={{
          position: 'relative',
          zIndex: 1
        }}
      >
        <StyledBottomNavigationAction 
          label={t('common.home')} 
          icon={<HomeIcon />} 
          onClick={() => handleNavigation('/')} 
        />
        <StyledBottomNavigationAction 
          label={t('common.flights')} 
          icon={<FlightIcon />} 
          onClick={() => handleNavigation('/search')} 
        />
        <StyledBottomNavigationAction 
          label={t('common.bookings')} 
          icon={<EventNoteIcon />} 
          onClick={() => handleNavigation('/bookings')} 
        />
        <StyledBottomNavigationAction 
          label={t('common.guide')} 
          icon={<GuideIcon />} 
          onClick={() => handleNavigation('/guide')} 
        /> 
        <StyledBottomNavigationAction 
          label={t('common.pets')} 
          icon={<PetsIcon />} 
          onClick={() => handleNavigation('/pets')} 
        />
        <Tooltip 
          title="Demo Profile" 
          placement="top"
          sx={{
            '& .MuiTooltip-tooltip': {
              backgroundColor: 'rgba(100, 149, 237, 0.9)',
              color: 'white',
              fontSize: '0.8rem',
              borderRadius: '8px',
              padding: '4px 8px',
            }
          }}
        >
          <StyledBottomNavigationAction 
            label={t('common.profile')} 
            icon={<AccountCircleIcon />} 
            onClick={() => handleNavigation('/profile')} 
          />
        </Tooltip>
        
      </StyledBottomNavigation>
    </Paper>
  );
};

export default BottomNav;
