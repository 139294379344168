import React, { useState, useEffect, useCallback } from 'react';
import { 
  Grid, 
  Paper, 
  Typography, 
  Autocomplete, 
  TextField,
  Box,
  Chip,
  CircularProgress,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Tooltip
} from '@mui/material';
import { 
  ExpandMore as ExpandMoreIcon,
  Warning as WarningIcon,
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  CompareArrows as CompareIcon,
  Pets as PetsIcon
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Country, CountryRule, Pet } from '../../types/types';

interface CountryRulesProps {
  userPet?: Pet;
}

interface RuleComparison {
  field: string;
  sourceValue: any;
  targetValue: any;
  importance: 'normal' | 'warning' | 'danger';
  description: string;
}

const CountryRules: React.FC<CountryRulesProps> = ({ userPet }) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<Country[]>([]);
  const [sourceCountry, setSourceCountry] = useState<Country | null>(null);
  const [targetCountry, setTargetCountry] = useState<Country | null>(null);
  const [sourceRules, setSourceRules] = useState<CountryRule | null>(null);
  const [targetRules, setTargetRules] = useState<CountryRule | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [ruleComparisons, setRuleComparisons] = useState<RuleComparison[]>([]);

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_GRAPHQL_URI}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
            query {
              countries {
                CountryID
                CountryName
                alpha3Code
                RabiesZone
              }
            }
          `
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { data } = await response.json();
      if (data && data.countries) {
        console.log('Received countries with RabiesZone:', data.countries);
        setCountries(data.countries);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
      setError(t('errors.fetchCountriesFailed'));
    } finally {
      setLoading(false);
    }
  };

  const fetchCountryRules = async (countryId: number, petTypeId: number = 1) => {
    try {
      console.log('Fetching rules for country:', countryId, 'petType:', petTypeId);
      const response = await fetch(`${process.env.REACT_APP_GRAPHQL_URI}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
            query GetCountryRules($countryId: ID!, $petTypeId: ID!) {
              countryRules(countryId: $countryId, petTypeId: $petTypeId) {
                departureRules {
                  CountryRuleID
                  Direction
                  CountryID
                  PetTypeID
                  Microchip
                  MicrochipCertificate
                  AnimalPassport
                  HealthCertificate
                  AdditionalTests
                  ParasiteTreatment
                  LastUpdate
                  AdditionalRequirements
                  IsBanned
                  RequiredVaccine
                  Permit
                }
                arrivalRules {
                  CountryRuleID
                  Direction
                  CountryID
                  PetTypeID
                  Microchip
                  MicrochipCertificate
                  AnimalPassport
                  HealthCertificate
                  AdditionalTests
                  ParasiteTreatment
                  LastUpdate
                  AdditionalRequirements
                  IsBanned
                  RequiredVaccine
                  Permit
                }
              }
            }
          `,
          variables: {
            countryId: countryId.toString(),
            petTypeId: petTypeId.toString()
          }
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('GraphQL response error:', errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const { data, errors } = await response.json();
      
      if (errors) {
        console.error('GraphQL Errors:', errors);
        throw new Error('GraphQL query failed');
      }

      console.log('Country rules response:', data);
      
      if (!data.countryRules?.departureRules && !data.countryRules?.arrivalRules) {
        console.log(`No rules found for country ${countryId} and pet type ${petTypeId}`);
        return {
          departureRules: {
            CountryRuleID: 0,
            Direction: 1,
            CountryID: countryId,
            PetTypeID: petTypeId,
            AnimalPassport: false,
            HealthCertificate: false,
            ParasiteTreatment: false,
            IsBanned: false,
            AdditionalRequirements: 'No specific rules found for this country'
          },
          arrivalRules: {
            CountryRuleID: 0,
            Direction: 2,
            CountryID: countryId,
            PetTypeID: petTypeId,
            AnimalPassport: false,
            HealthCertificate: false,
            ParasiteTreatment: false,
            IsBanned: false,
            AdditionalRequirements: 'No specific rules found for this country'
          }
        };
      }

      return data.countryRules;
    } catch (error) {
      console.error('Error fetching country rules:', error);
      throw error;
    }
  };

  // Функция получения цвета для зоны бешенства
  const getRabiesZoneColor = (zone?: number): "success" | "warning" | "error" | "default" => {
    switch (zone) {
      case 1: return "success";
      case 2: return "warning";
      case 3: return "error";
      default: return "default";
    }
  };

  const getRabiesZoneLabel = (zone?: number): string => {
    switch (zone) {
      case 1: return t('guide.countries.rabiesZoneLow');
      case 2: return t('guide.countries.rabiesZoneMedium');
      case 3: return t('guide.countries.rabiesZoneHigh');
      default: return t('guide.countries.rabiesZoneUnknown');
    }
  };

  // Функция сравнения правил
  const compareRules = useCallback((sourceRule?: CountryRule, targetRule?: CountryRule) => {
    if (!sourceRule || !targetRule || !sourceCountry || !targetCountry) return;

    const comparisons: RuleComparison[] = [];

    // Сравнение зон бешенства
    if (sourceCountry.RabiesZone !== targetCountry.RabiesZone) {
      const sourceZone = sourceCountry.RabiesZone || 'unknown';
      const targetZone = targetCountry.RabiesZone || 'unknown';
      
      comparisons.push({
        field: 'rabiesZone',
        sourceValue: sourceZone,
        targetValue: targetZone,
        importance: 'warning',
        description: t('guide.countries.rabiesZoneDifference', {
          source: sourceZone,
          target: targetZone
        })
      });
    }

    const sourceRabiesZone = sourceCountry.RabiesZone;
    const targetRabiesZone = targetCountry.RabiesZone;

    if (sourceRabiesZone != null && targetRabiesZone != null && sourceRabiesZone !== targetRabiesZone) {
      const getRabiesZoneDescription = (zone: number) => {
        switch (zone) {
          case 1: return t('guide.countries.rabiesZoneLow');
          case 2: return t('guide.countries.rabiesZoneMedium');
          case 3: return t('guide.countries.rabiesZoneHigh');
          default: return t('guide.countries.rabiesZoneUnknown');
        }
      };

      comparisons.push({
        field: 'rabiesZone',
        sourceValue: sourceRabiesZone,
        targetValue: targetRabiesZone,
        importance: targetRabiesZone > sourceRabiesZone ? 'danger' : 'warning',
        description: t('guide.countries.rabiesZoneDifference', {
          source: getRabiesZoneDescription(sourceRabiesZone),
          target: getRabiesZoneDescription(targetRabiesZone)
        })
      });
    }

    // Сравнение вакцин
    const sourceVaccines = sourceRule.RequiredVaccine?.required || [];
    const targetVaccines = targetRule.RequiredVaccine?.required || [];
    const additionalVaccines = targetVaccines.filter(v => !sourceVaccines.includes(v));
    
    if (additionalVaccines.length > 0) {
      comparisons.push({
        field: 'vaccines',
        sourceValue: sourceVaccines,
        targetValue: targetVaccines,
        importance: 'danger',
        description: t('guide.countries.additionalVaccinesRequired', {
          vaccines: additionalVaccines.map(v => t(`vaccines.${v}`)).join(', ')
        })
      });
    }

    setRuleComparisons(comparisons);
  }, [sourceCountry, targetCountry, sourceRules, targetRules, t]);

  // Функция отображения секции правил
  const renderRuleSection = (rule: CountryRule | undefined, isSource: boolean) => {
    if (!rule) return null;

    const country = isSource ? sourceCountry : targetCountry;

    // Парсим RequiredVaccine если это строка
    const vaccineData = typeof rule.RequiredVaccine === 'string' 
      ? JSON.parse(rule.RequiredVaccine)
      : rule.RequiredVaccine;
        // Парсим Permit если это строка
        const permitIds = rule.Permit 
          ? (typeof rule.Permit === 'string' ? rule.Permit.split(',') : rule.Permit)
          : [];
    return (
      <Box sx={{ mt: 2 }}>
        {country?.RabiesZone && (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle2" color="text.secondary">
              {t('guide.countries.rabiesZone')}:
            </Typography>
            <Chip
              label={getRabiesZoneLabel(country.RabiesZone)}
              color={getRabiesZoneColor(country.RabiesZone)}
              size="small"
            />
          </Box>
        )}

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{t('guide.countries.requiredDocuments')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              {rule.AnimalPassport && (
                <Grid item>
                  <Tooltip title={t('guide.countries.passportRequired')}>
                    <Chip
                      icon={<CheckCircleIcon />}
                      label={t('guide.countries.passport')}
                      color="success"
                    />
                  </Tooltip>
                </Grid>
              )}
              {rule.HealthCertificate && (
                <Grid item>
                  <Tooltip title={t('guide.countries.healthCertRequired')}>
                    <Chip
                      icon={<CheckCircleIcon />}
                      label={t('guide.countries.healthCert')}
                      color="success"
                    />
                  </Tooltip>
                </Grid>
              )}
              {rule.MicrochipCertificate && (
                <Grid item>
                  <Tooltip title={rule.Microchip || t('guide.countries.microchipRequired')}>
                    <Chip
                      icon={<CheckCircleIcon />}
                      label={t('guide.countries.microchip')}
                      color="success"
                    />
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{t('guide.countries.vaccinations')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" color="text.secondary">
                  {t('guide.countries.requiredVaccines')}:
                </Typography>
                {vaccineData?.required?.map((vaccineId: number) => (
                  <Chip
                    key={vaccineId}
                    label={t(`vaccines.${vaccineId}`)}
                    color="primary"
                    sx={{ m: 0.5 }}
                  />
                ))}
              </Grid>
              {vaccineData?.recommended?.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" color="text.secondary">
                    {t('guide.countries.recommendedVaccines')}:
                  </Typography>
                  {vaccineData.recommended.flat().map((vaccineId: number) => (
                    <Chip
                      key={vaccineId}
                      label={t(`vaccines.${vaccineId}`)}
                      color="default"
                      sx={{ m: 0.5 }}
                    />
                  ))}
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>

        {rule.AdditionalRequirements && (
          <Alert severity="info" sx={{ mt: 2 }}>
            <Typography variant="subtitle2">
              {t('guide.countries.petSpecificInfo')}:<br></br>
              {userPet?.PetName} - {t(`pets.types.${userPet?.PetTypeID}`)}
              
            </Typography>
            <Typography variant="body2">
            {t('guide.countries.additionalRequirements')}:
              <br></br>
              {rule.AdditionalRequirements}
            </Typography>
          </Alert>
        )}
      </Box>
    );
  };

  // Обработчики выбора стран
  const handleSourceCountryChange = async (newValue: Country | null) => {
    setSourceCountry(newValue);
    if (newValue) {
      try {
        // Используем CountryID из выбранной страны
        const rules = await fetchCountryRules(newValue.CountryID, 1);
        setSourceRules(rules.departureRules);
        if (targetRules) {
          compareRules(rules.departureRules, targetRules);
        }
      } catch (error) {
        console.error('Error fetching source country rules:', error);
      }
    }
  };

  const handleTargetCountryChange = async (newValue: Country | null) => {
    setTargetCountry(newValue);
    if (newValue) {
      try {
        // Используем CountryID из выбранной страны
        const rules = await fetchCountryRules(newValue.CountryID, 1);
        setTargetRules(rules.arrivalRules);
        if (sourceRules) {
          compareRules(sourceRules, rules.arrivalRules);
        }
      } catch (error) {
        console.error('Error fetching target country rules:', error);
      }
    }
  };

  return (
    <Box>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          {ruleComparisons.length > 0 && (
            <Alert severity="warning" sx={{ mb: 3 }}>
              <Typography variant="subtitle1">
                {t('guide.countries.importantDifferences')}:
              </Typography>
              {ruleComparisons.map((comparison, index) => (
                <Typography key={index} variant="body2">
                  • {comparison.description}
                </Typography>
              ))}
            </Alert>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  {t('guide.countries.departure')}
                </Typography>
                <Autocomplete
                  options={countries}
                  getOptionLabel={(option) => `${option.CountryName} (${option.alpha3Code})`}
                  value={sourceCountry}
                  onChange={(_, newValue) => handleSourceCountryChange(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('guide.countries.selectDepartureCountry')}
                      variant="outlined"
                    />
                  )}
                />
                {sourceRules && renderRuleSection(sourceRules, true)}
              </Paper>
            </Grid>

            <Grid item xs={12} md={6} mb={6}>
              <Paper sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>
                  {t('guide.countries.arrival')}
                </Typography>
                <Autocomplete
                  options={countries}
                  getOptionLabel={(option) => `${option.CountryName} (${option.alpha3Code})`}
                  value={targetCountry}
                  onChange={(_, newValue) => handleTargetCountryChange(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('guide.countries.selectArrivalCountry')}
                      variant="outlined"
                    />
                  )}
                />
                {targetRules && renderRuleSection(targetRules, false)}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default CountryRules; 
