import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Tabs,
  Tab,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  Public as CountriesIcon,
  Flight as AirlinesIcon,
  LocalHospital as VetsIcon,
  LocationOn as AirportsIcon,
  Pets as PetServicesIcon
} from '@mui/icons-material';

// Импортируем компоненты секций
import CountryRules from './CountryRules';
import AirlineRules from './AirlineRules';
import VetServices from './VetServices';
import AirportInfo from './AirportInfo';
import PetServices from './PetServices';

const Guide: React.FC = () => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState('countries');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Определяем секции справочника
  const sections = [
    { id: 'countries', icon: <CountriesIcon />, title: t('guide.sections.countries') },
    { id: 'airlines', icon: <AirlinesIcon />, title: t('guide.sections.airlines') },
    { id: 'vets', icon: <VetsIcon />, title: t('guide.sections.vets') },
    { id: 'airports', icon: <AirportsIcon />, title: t('guide.sections.airports') },
    { id: 'services', icon: <PetServicesIcon />, title: t('guide.sections.services') }
  ];

  return (
    <Box sx={{ pt: 8, px: 4 }}>
      {/* Навигационные табы */}
      <Tabs
        value={activeSection}
        onChange={(_, newValue) => setActiveSection(newValue)}
        variant={isMobile ? "scrollable" : "standard"}
        scrollButtons="auto"
        centered={!isMobile}
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          mb: 3
        }}
      >
        {sections.map(section => (
          <Tab
            key={section.id}
            value={section.id}
            icon={section.icon}
            label={section.title}
            sx={{
              minHeight: 72,
              '& .MuiTab-iconWrapper': {
                mb: 1
              }
            }}
          />
        ))}
      </Tabs>

      {/* Контент активной секции */}
      <Box sx={{ mt: 3 }}>
        {activeSection === 'countries' && <CountryRules />}
        {activeSection === 'airlines' && <AirlineRules />}
        {activeSection === 'vets' && <VetServices />}
        {activeSection === 'airports' && <AirportInfo />}
        {activeSection === 'services' && <PetServices />}
      </Box>
    </Box>
  );
};

export default Guide; 