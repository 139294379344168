// frontend/web/src/App.tsx

import React, { lazy, Suspense, useState, useEffect } from 'react';
import './App.css'; // Добавьте эту строку, если ее нет
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { Box, AppBar, Container } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { DeviceContext } from './contexts/DeviceContext';
import { CurrencyProvider } from './contexts/CurrencyContext';
import { TelegramProvider } from './contexts/TelegramContext';

import Header from './components/Header';
import Footer from './components/Footer';
import LoadingFallback from './components/LoadingFallback';
import ErrorBoundary from './components/ErrorBoundary';
import NotificationSystem from './components/NotificationSystem';
import ProtectedRoute from './components/ProtectedRoute';
import BottomNav from './components/BottomNavigation';

import { store } from './store';
import UserProfile from './components/UserProfile';
import ScrollToTop from './components/ScrollToTop';
import { initAmplitude } from './utils/amplitude';
import { logEvent, AmplitudeEvents } from './utils/amplitude';
import Guide from './components/Guide/Guide';

const Home = lazy(() => import('./pages/Home'));
const SearchForm = lazy(() => import('./components/SearchForm'));
const FlightListing = lazy(() => import('./components/FlightListing'));
const DetailedFlightView = lazy(() => import('./components/DetailedFlightView'));
const Login = lazy(() => import('./components/Login'));
const Register = lazy(() => import('./components/Register'));
const Landing = lazy(() => import('./components/Landing'));
const SimpleFlightSearch = lazy(() => import('./components/SimpleFlightSearch'));
const Rules = lazy(() => import('./components/Rules'));
const AdaptiveLanding = lazy(() => import('./components/AdaptiveLanding'));
const SerpApiFlightSearch = lazy(() => import('./components/SerpApiFlightSearch'));
const About = lazy(() => import('./pages/About'));
const Bookings = lazy(() => import('./pages/Bookings'));
const Pets = lazy(() => import('./pages/Pets'));

const queryClient = new QueryClient();

const theme = createTheme({
  typography: {
    fontFamily: '"Nunito", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: { fontWeight: 700 },
    h2: { fontWeight: 700 },
    h3: { fontWeight: 600 },
    h4: { fontWeight: 600 },
    h5: { fontWeight: 500 },
    h6: { fontWeight: 500 },
    button: { fontWeight: 700 },
    subtitle1: { fontWeight: 600 },
    subtitle2: { fontWeight: 500 },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        }
      }
    }
  }
});

// Создаем отдельный компонент для отслеживания маршрутов
const RouteTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    logEvent(AmplitudeEvents.PAGE_VIEW, {
      path: location.pathname,
      search: location.search,
      title: document.title
    });
  }, [location]);

  return null;
};

// Функция определения поддерживаемого языка
const getSupportedLanguage = (userLanguage: string): string => {
  // Список поддерживаемых языков из вашего приложения
  const supportedLanguages = ['en', 'ru', 'de', 'fr', 'es'];
  
  // Получаем короткий код языка (например, из 'ru-RU' получаем 'ru')
  const shortLang = userLanguage.split('-')[0].toLowerCase();
  
  // Проверяем, поддерживается ли язык
  return supportedLanguages.includes(shortLang) ? shortLang : 'en';
};

const App: React.FC = () => {
  const [currentDevice, setCurrentDevice] = useState('desktop');

  // Инициализация Amplitude только один раз при запуске приложения
  useEffect(() => {
    initAmplitude();
  }, []);

  useEffect(() => {
    // Определяем язык пользователя
    const detectAndSetLanguage = () => {
      try {
        // Пробуем получить язык из navigator
        const userLanguage = 
          navigator.language || 
          (navigator as any).userLanguage || 
          (navigator as any).browserLanguage || 
          'en';

        // Получаем поддерживаемый язык
        const supportedLang = getSupportedLanguage(userLanguage);

        // Логируем событие в Amplitude
        logEvent(AmplitudeEvents.LANGUAGE_AUTO_DETECTED, {
          detectedLanguage: userLanguage,
          appliedLanguage: supportedLang
        });

        // Устанавливаем язык
        i18n.changeLanguage(supportedLang);
      } catch (error) {
        console.error('Error detecting language:', error);
        // В случае ошибки устанавливаем английский
        i18n.changeLanguage('en');
      }
    };

    detectAndSetLanguage();
  }, []); // Выполняется один раз при монтировании

  return (
    <GoogleOAuthProvider clientId="591705899621-rcguk1v2msu6c8mtnspojpflltf8r97g.apps.googleusercontent.com">
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
              <CurrencyProvider>
                <DeviceContext.Provider value={currentDevice}>
                  <TelegramProvider>
                    <SnackbarProvider maxSnack={3}>
                      <Router>
                        <RouteTracker />
                        <ScrollToTop />
                        <ErrorBoundary>
                          <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', position: 'relative' }}>
                            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                              <Header />
                            </AppBar>
                            <NotificationSystem />
                            <Box component="main" sx={{ flexGrow: 1, paddingTop: '0px' }}>
                              <Suspense fallback={<LoadingFallback />}>
                                <Routes>
                                  <Route path="/guide" element={<Guide />} />
                                  <Route path="/" element={<Home />} />
                                  <Route path="/search" element={<SearchForm />} />
                                  <Route path="/flights" element={<FlightListing />} />
                                  <Route path="/flight-details" element={<DetailedFlightView />} />
                                  <Route path="/login" element={<Login />} />
                                  <Route path="/register" element={<Register />} />
                                  <Route path="/profile" element={<UserProfile />} />
                                  <Route path="/landing" element={<Landing />} />
                                  <Route path="/simple-flight-search" element={<SimpleFlightSearch />} />
                                  <Route path="/rules" element={<Rules />} />
                                  <Route path="/adaptive-landing" element={<AdaptiveLanding userId="anonymous" />} />
                                  <Route path="/serp-flight-search" element={<SerpApiFlightSearch />} />
                                  <Route path="/about" element={<About />} />
                                  <Route path="/bookings" element={<Bookings />} />
                                  <Route path="/pets" element={<Pets />} />
                                </Routes>
                              </Suspense>
                            </Box>
                            <Footer />
                            <BottomNav />
                          </Box>
                        </ErrorBoundary>
                      </Router>
                    </SnackbarProvider>
                  </TelegramProvider>
                </DeviceContext.Provider>
              </CurrencyProvider>
            </QueryClientProvider>
          </ThemeProvider>
        </Provider>
      </I18nextProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
