import React from 'react';
import { Box, Typography } from '@mui/material';

const AirportInfo: React.FC = () => {
  return (
    <Box>
      <Typography variant="h5">Airport Information</Typography>
      {/* Контент информации об аэропортах */}
    </Box>
  );
};

export default AirportInfo;