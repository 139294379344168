import React from 'react';
import { Box, Typography } from '@mui/material';

const VetServices: React.FC = () => {
  return (
    <Box>
      <Typography variant="h5">Veterinary Services</Typography>
      {/* Контент ветеринарных сервисов */}
    </Box>
  );
};

export default VetServices; 