import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Snackbar, Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { clearNotification } from '../store/notificationSlice';

const NotificationSystem: React.FC = () => {
  const { t } = useTranslation();
  const notification = useSelector((state: any) => state.notification);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (notification.message) {
      setOpen(true);
    }
  }, [notification]);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    dispatch(clearNotification());
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={notification.type} sx={{ width: '100%' }}>
        {t(notification.message)}
      </Alert>
    </Snackbar>
  );
};

export default NotificationSystem;
