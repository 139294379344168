import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

interface User {
  id: string;
  name: string;
  email: string;
  phone?: string;
  avatar?: string;
  status: 'waitlist' | 'active' | 'invited';
  memberSince?: string;
  verified?: boolean;
  company?: string;
  isWaitlist: boolean;
  waitlistJoinDate?: string;
  source: 'form' | 'google';
  consentGiven: boolean;
  googleId?: string;
  preferences?: {
    language?: string;
    currency?: string;
    timezone?: string;
    notifications?: {
      email: boolean;
      push: boolean;
      sms: boolean;
    };
    newsletter?: boolean;
  };
  cityId?: number;
  countryId?: number;
  preferredAirlineId?: number;
  frequentFlyerNumber?: string;
  emergencyContact?: string;
  registrationDate?: string;
  createdAt: string;
  updatedAt: string;
}

interface LoginCredentials {
  username: string;
  password: string;
}

interface RegisterCredentials {
  username: string;
  password: string;
}

interface WaitlistPayload {
  name: string;
  email: string;
  company?: string;
  recaptcha: string;
  consent: boolean;
}

interface GoogleWaitlistPayload {
  credential: string;
}

// Создаем инстанс axios с базовым URL
const api = axios.create({
  baseURL: process.env.NODE_ENV === 'production' 
    ? 'https://avia.pet' 
    : 'http://localhost:5001',
  withCredentials: true
});

export const login = createAsyncThunk<User, LoginCredentials>(
  "auth/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axios.post<{ user: User; token: string }>("/api/users/login", credentials);
      localStorage.setItem("token", response.data.token);
      return response.data.user;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('An unknown error occurred');
    }
  }
);

export const registerUser = createAsyncThunk<User, RegisterCredentials>(
  "auth/register",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axios.post<{ user: User; token: string }>("/api/users/register", userData);
      localStorage.setItem("token", response.data.token);
      return response.data.user;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data);
      }
      return rejectWithValue('An unknown error occurred');
    }
  }
);

export const joinWaitlist = createAsyncThunk<User, WaitlistPayload>(
  "auth/joinWaitlist",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await api.post<{ user: User; token: string }>(
        "/api/waitlist/join", 
        userData
      );
      localStorage.setItem("token", response.data.token);
      return response.data.user;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data || 'Network error');
      }
      return rejectWithValue('An unknown error occurred');
    }
  }
);

export const joinWaitlistWithGoogle = createAsyncThunk<User, string>(
  "auth/joinWaitlistWithGoogle",
  async (credential, { rejectWithValue }) => {
    try {
      const response = await api.post<{ user: User; token: string }>(
        "/api/waitlist/google", 
        { credential }
      );
      localStorage.setItem("token", response.data.token);
      return response.data.user;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return rejectWithValue(error.response?.data || 'Network error');
      }
      return rejectWithValue('An unknown error occurred');
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null as User | null,
    status: "idle" as "idle" | "loading" | "succeeded" | "failed",
    error: null as string | null,
  },
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(registerUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(joinWaitlist.pending, (state) => {
        state.status = "loading";
      })
      .addCase(joinWaitlist.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(joinWaitlist.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(joinWaitlistWithGoogle.pending, (state) => {
        state.status = "loading";
      })
      .addCase(joinWaitlistWithGoogle.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(joinWaitlistWithGoogle.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
