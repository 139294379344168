import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CurrencyProvider } from './contexts/CurrencyContext';
import { createGlobalStyle } from 'styled-components';

// Функция для логирования
const log = (message: string, data?: any) => {
  console.log(`[${new Date().toISOString()}] ${message}`, data ? JSON.stringify(data, null, 2) : '');
};

// Логируем информацию о запуске приложения
log('Приложение запускается', {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_GRAPHQL_URI: process.env.REACT_APP_GRAPHQL_URI,
  REACT_APP_API_BASE_URL: process.env.REACT_APP_API_BASE_URL
});

// Используем переменную окружения для URI GraphQL
const graphqlUri = process.env.REACT_APP_GRAPHQL_URI || 'http://localhost:5001/graphql';
log('Используется GraphQL URI', graphqlUri);

const client = new ApolloClient({
  uri: graphqlUri,
  cache: new InMemoryCache()
});

// Добавьте глобальный обработчик ошибок
window.onerror = (message, source, lineno, colno, error) => {
  console.error('Глобальная ошибка:', { message, source, lineno, colno, error });
  // Можно добавить отправку ошибки в систему мониторинга
  return false;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Оборачиваем рендеринг в try-catch для отлова возможных ошибок
try {
  const GlobalStyle = createGlobalStyle`
    body {
      background-color: transparent;
    }
  `;
  root.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <CurrencyProvider>
          <App />
        </CurrencyProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
  log('Приложение успешно отрендерено');
} catch (error) {
  log('Ошибка при рендеринге приложения', error);
}

reportWebVitals((metric) => {
  log('Web Vitals', metric);
});
