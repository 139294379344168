import { init, track, Types } from '@amplitude/analytics-browser';

/**
 * Типы событий для аналитики в AVIAPET
 * Сгруппированы по основным разделам функциональности
 */
export enum AmplitudeEvents {
  // ================ ПОИСК И РЕЗУЛЬТАТЫ ================
  /** Пользователь начал поиск рейсов */
  SEARCH_STARTED = 'search_started',
  /** Поиск успешно завершен и найдены рейсы */
  SEARCH_SUCCESS = 'search_success',
  /** Произошла ошибка при поиске */
  SEARCH_ERROR = 'search_error',
  /** Поиск завершен, но рейсы не найдены */
  SEARCH_NO_RESULTS = 'search_no_results',

  // ================ ПАРАМЕТРЫ ПОИСКА ================
  /** Выбран тип питомца (кошка/собака/кролик) */
  PET_TYPE_SELECTED = 'pet_type_selected',
  /** Выбрана порода питомца */
  BREED_SELECTED = 'breed_selected',
  /** Выбран тип переноски */
  CARRIER_TYPE_SELECTED = 'carrier_type_selected',
  /** Пользователь поменял местами пункты отправления и назначения */
  ROUTE_SWAPPED = 'route_swapped',
  /** Форма была автоматически заполнена */
  FORM_AUTO_FILLED = 'form_auto_filled',

  // ================ НАВИГАЦИЯ И ПРОСМОТРЫ ================
  /** Просмотр страницы */
  PAGE_VIEW = 'page_view',
  /** Клик по элементу навигации */
  NAVIGATION_CLICK = 'navigation_click',
  /** Просмотр списка рейсов */
  FLIGHT_LIST_VIEW = 'flight_list_view',
  /** Клик по карточке рейса для просмотра деталей */
  FLIGHT_DETAILS_CLICK = 'flight_details_click',
  /** Просмотр детальной информации о рейсе */
  FLIGHT_DETAILS_VIEW = 'flight_details_view',
  /** Возврат из деталей рейса к списку */
  FLIGHT_DETAILS_BACK = 'flight_details_back',
  /** Уход со страницы деталей рейса */
  FLIGHT_DETAILS_EXIT = 'flight_details_exit',
  /** Выбор пресета маршрута */
  PRESET_ROUTE_SELECTED = 'preset_route_selected',

  // ================ ПЕРЕХОД К ПОКУПКЕ ================
  /** Клик по кнопке "Купить билет" */
  PURCHASE_CLICK = 'purchase_click',
  /** Успешный переход на сайт авиакомпании */
  AIRLINE_REDIRECT = 'airline_redirect',
  /** Пользователь отменил переход к покупке */
  PURCHASE_CANCELLED = 'purchase_cancelled',

  // ================ Изучение правил ================
  POLICY_LINK_CLICK = 'policy_link_click',
  VACCINE_DETAILS_VIEW = 'vaccine_details_view',
  DOCUMENT_DETAILS_VIEW = 'document_details_view',

// ================ ЛИСТ ОЖИДАНИЯ ================
  WAITLIST_FORM_OPEN = 'waitlist_form_open',
  WAITLIST_FORM_CLOSE = 'waitlist_form_close',
  WAITLIST_SUBMIT_ATTEMPT = 'waitlist_submit_attempt',
  WAITLIST_SUBMIT_SUCCESS = 'waitlist_submit_success',
  WAITLIST_SUBMIT_ERROR = 'waitlist_submit_error',
  WAITLIST_GOOGLE_ATTEMPT = 'waitlist_google_attempt',
  WAITLIST_GOOGLE_SUCCESS = 'waitlist_google_success',
  WAITLIST_GOOGLE_ERROR = 'waitlist_google_error',
 
  // ================ ПОЛЬЗОВАТЕЛЬСКИЕ НАСТРОЙКИ ================
  /** Смена валюты отображения цен */
  CURRENCY_CHANGE = 'currency_change',
  /** Смена языка интерфейса */
  LANGUAGE_CHANGE = 'language_change',

  // ================ ОШИБКИ И ВАЛИДАЦИЯ ================
  /** Возникла ошибка в приложении */
  ERROR_OCCURRED = 'error_occurred',
  /** Ошибка валидации формы */
  VALIDATION_ERROR = 'validation_error',

  LANGUAGE_AUTO_DETECTED = 'language_auto_detected',
}

/**
 * Инициализация Amplitude с настройками для EU региона
 * Включает автоматическое отслеживание базовых метрик
 */
export const initAmplitude = () => {
  init('9068174fb00561a02644757174e1bb70', undefined, {
    serverZone: 'EU', // Указываем EU сервер для GDPR
    defaultTracking: {
      pageViews: true,    // Автоматическое отслеживание просмотров страниц
      sessions: true,     // Отслеживание сессий
      formInteractions: true, // Отслеживание взаимодействий с формами
      fileDownloads: true    // Отслеживание загрузок файлов
    },
    logLevel: Types.LogLevel.Warn, // Уровень логирования
    minIdLength: 1 // Минимальная длина ID для валидных событий
  });
};

/**
 * Хелпер для отправки событий в Amplitude
 * @param eventName - Название события из enum AmplitudeEvents
 * @param eventProperties - Дополнительные свойства события
 * 
 * Пример использования:
 * logEvent(AmplitudeEvents.SEARCH_STARTED, {
 *   origin: 'Moscow',
 *   destination: 'Paris',
 *   petType: 'cat'
 * });
 */
export const logEvent = (
  eventName: AmplitudeEvents, 
  eventProperties?: Record<string, any>
) => {
  try {
    track(eventName, {
      timestamp: Date.now(),
      ...eventProperties
    });
    // Для отладки в development
    if (process.env.NODE_ENV === 'development') {
      console.debug('[Amplitude Event]', { 
        eventName, 
        eventProperties 
      });
    }
  } catch (error) {
    console.error('[Amplitude Error]', error);
  }
}; 