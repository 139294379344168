import React, { useState, useEffect } from 'react';
import { 
  Typography, Container, Grid, Box, Chip, Avatar,
  List, ListItem, ListItemText, ListItemIcon, Divider,
  IconButton, Tooltip, Button, Card, CardContent,
  TextField, Switch, Alert, Stack, Badge, Tab, Tabs,
  Dialog, DialogTitle, DialogContent, DialogActions,
  Snackbar, FormControlLabel, Checkbox, Link,
  LinearProgress, CircularProgress
} from '@mui/material';
import { styled } from '@mui/system';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import {
  Person, Edit, Notifications, Security, Language,
  Settings, Pets, FlightTakeoff, CreditCard, History,
  LocationOn, Favorite, NotificationsActive, VpnKey,
  Email, Phone, Telegram, WhatsApp, Facebook, Google,
  Apple, CheckCircle, Warning, AddAPhoto, Save, Close,
  VerifiedUser, ContactSupport, AccessTime, Payment,
  Loyalty, LocalOffer, Wallet, AccountBox, Add,
  MoreVert, AttachMoney, Message
} from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { joinWaitlist, joinWaitlistWithGoogle } from '../store/authSlice';
import { useSnackbar } from 'notistack';
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { logEvent, AmplitudeEvents } from '../utils/amplitude';

interface WaitlistFormData {
  name: string;
  email: string;
  company?: string;
  consent: boolean;
}

interface GoogleCredentialResponse {
  credential: string;
}

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 3,
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
  },
}));

const ProfileAvatar = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

interface UserProfile {
  id: string;
  name: string;
  email: string;
  phone: string;
  avatar: string;
  status: 'active' | 'inactive';
  memberSince: string;
  verified: boolean;
  preferences: {
    language: string;
    currency: string;
    timezone: string;
    notifications: {
      email: boolean;
      push: boolean;
      sms: boolean;
    };
    newsletter: boolean;
  };
  stats: {
    totalTrips: number;
    totalPets: number;
    countriesVisited: number;
    totalSpent: string;
  };
  pets: Array<{
    id: string;
    name: string;
    type: string;
    avatar: string;
    age: number;
    lastCheckup: string;
  }>;
  travelHistory: Array<{
    id: string;
    date: string;
    destination: string;
    pet: string;
    status: 'completed' | 'upcoming' | 'cancelled';
  }>;
  paymentMethods: Array<{
    id: string;
    type: 'card' | 'paypal';
    name: string;
    last4?: string;
    expiry?: string;
    primary: boolean;
  }>;
  documents: Array<{
    id: string;
    type: string;
    name: string;
    status: 'valid' | 'expired' | 'pending';
    expiry: string;
  }>;
  socialAccounts: {
    google?: string;
    facebook?: string;
    apple?: string;
  };
  loyalty: {
    tier: 'bronze' | 'silver' | 'gold' | 'platinum';
    points: number;
    nextTier: {
      name: string;
      pointsNeeded: number;
    };
  };
}

const getStatusColor = (status: string): 'success' | 'warning' | 'error' | 'default' => {
  switch (status.toLowerCase()) {
    case 'completed':
      return 'success';
    case 'upcoming':
      return 'warning';
    case 'cancelled':
      return 'error';
    default:
      return 'default';
  }
};

const DemoHeader: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { enqueueSnackbar } = useSnackbar();
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    consent: false
  });
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  useEffect(() => {
    if (!recaptchaSiteKey) {
      console.error('Missing REACT_APP_RECAPTCHA_SITE_KEY environment variable');
    }
  }, []);

  const handleRecaptchaChange = (value: string | null) => {
    setRecaptchaValue(value);
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Логируем попытку подписки
    logEvent(AmplitudeEvents.WAITLIST_SUBMIT_ATTEMPT, {
      email: formData.email,
      hasCompany: !!formData.company,
      source: 'form'
    });

    // Валидация формы
    if (!formData.name.trim()) {
      enqueueSnackbar(t('waitlist.errors.nameRequired'), { variant: 'error' });
      return;
    }

    if (!validateEmail(formData.email)) {
      enqueueSnackbar(t('waitlist.errors.invalidEmail'), { variant: 'error' });
      return;
    }

    if (!formData.consent) {
      enqueueSnackbar(t('waitlist.errors.consentRequired'), { variant: 'error' });
      return;
    }

    try {
      setLoading(true);
      await dispatch(joinWaitlist({
        ...formData,
        recaptcha: recaptchaValue || ''
      })).unwrap();
      
      // Логируем успешную подписку
      logEvent(AmplitudeEvents.WAITLIST_SUBMIT_SUCCESS, {
        email: formData.email,
        hasCompany: !!formData.company,
        source: 'form'
      });

      setShowWaitlistForm(false);
      enqueueSnackbar(t('waitlist.success'), { variant: 'success' });
      
      // Очищаем форму после успешной отправки
      setFormData({
        name: '',
        email: '',
        company: '',
        consent: false
      });
      setRecaptchaValue(null);
    } catch (error: any) {
      // Логируем ошибку подписки
      logEvent(AmplitudeEvents.WAITLIST_SUBMIT_ERROR, {
        email: formData.email,
        error: error instanceof Error ? error.message : 'Unknown error',
        source: 'form'
      });

      console.error('Waitlist error:', error);
      
      // Более детальная обработка ошибок
      if (error.error === 'Server configuration error') {
        enqueueSnackbar(t('waitlist.errors.serverError'), { 
          variant: 'error',
          autoHideDuration: 5000
        });
      } else {
        enqueueSnackbar(error.error || t('waitlist.errors.unknown'), { 
          variant: 'error' 
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSuccess = async (response: any) => {
    // Логируем попытку Google авторизации
    logEvent(AmplitudeEvents.WAITLIST_GOOGLE_ATTEMPT);

    try {
      setLoading(true);
      const decoded = jwtDecode(response.credential);
      console.log('Decoded Google response:', decoded); // для отладки

      await dispatch(joinWaitlistWithGoogle(response.credential)).unwrap();

      // Логируем успешную Google авторизацию
      logEvent(AmplitudeEvents.WAITLIST_GOOGLE_SUCCESS, {
        hasEmail: !!(decoded as { email?: string }).email
      });

      enqueueSnackbar(t('waitlist.success'), { variant: 'success' });
    } catch (error) {
      // Логируем ошибку Google авторизации
      logEvent(AmplitudeEvents.WAITLIST_GOOGLE_ERROR, {
        error: error instanceof Error ? error.message : 'Unknown error'
      });

      if (error instanceof Error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      } else {
        enqueueSnackbar(t('waitlist.errors.unknown'), { variant: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  // Логируем открытие формы
  const handleOpenWaitlistForm = () => {
    logEvent(AmplitudeEvents.WAITLIST_FORM_OPEN);
    setShowWaitlistForm(true);
  };

  // Логируем закрытие формы
  const handleCloseWaitlistForm = () => {
    if (!loading) {
      logEvent(AmplitudeEvents.WAITLIST_FORM_CLOSE);
      setShowWaitlistForm(false);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        top: { xs: 65, sm: 80 },
        mx: 0,
        left: 0,
        right: 0,
        zIndex: 1100,
        background: `linear-gradient(45deg, 
          rgba(100, 150, 255, 0.4),
          rgba(80, 130, 255, 0.4)
        )`,
        backgroundSize: '400% 400%',
        backdropFilter: 'blur(8px)',
        borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        padding: { xs: '0px', sm: '0px' },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `repeating-linear-gradient(
            45deg,
            transparent,
            transparent 10px,
            rgba(255, 255, 255, 0.1) 10px,
            rgba(255, 255, 255, 0.1) 20px
          )`,
          zIndex: -1,
        }
      }}
    >
      <Container sx={{ padding: { xs: '0px', sm: '0px' }, mx: 0, width: '100%' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: 'center',
            gap: 0,
            padding: { xs: '20px', sm: '30px' },
            position: 'relative',
          }}
        >
          <Box sx={{ flex: 1, color: 'white', mx: 3, p: 2, background: 'linear-gradient(45deg, rgba(0, 113, 226, 0.85), rgba(0, 166, 255, 0.70))', borderRadius: '12px'}}>
            <Typography variant="h6" sx={{ fontWeight: 800, fontSize: { xs: '0.9rem', sm: '1.1rem' } }}>
              🚀 {t('demoHeader.message')}
            </Typography>
          </Box>
          
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: { xs: 2, sm: 0 } }}>
            <Button
              variant="contained"
              onClick={() => setShowWaitlistForm(true)}
              sx={{
                lineHeight: { xs: '1.1', sm: '1.7' },
                borderRadius: '25px',
                bgcolor: 'white',
                color: '#6478FF',
                '&:hover': {
                  bgcolor: 'rgba(255, 255, 255, 0.9)',
                },
              }}
            >
              {t('demoHeader.joinWaitlist')}
            </Button>
            
            <GoogleLogin
              onSuccess={handleGoogleSuccess}
              onError={() => {
                enqueueSnackbar(t('waitlist.errors.googleAuthFailed'), { 
                  variant: 'error' 
                });
              }}
              useOneTap
              theme="filled_blue"
              shape="pill"
              locale="ru"
              text="continue_with"
            />
          </Box>
        </Box>
      </Container>

      {/* Waitlist Dialog */}
      <Dialog 
        open={showWaitlistForm}
        onClose={() => !loading && setShowWaitlistForm(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '20px',
            background: 'linear-gradient(to right, #ffffff, #f8f9ff)',
            boxShadow: '0 8px 32px rgba(100, 120, 255, 0.1)',
            padding: '20px'
          }
        }}
      >
        <DialogTitle sx={{ 
          textAlign: 'center',
          fontSize: '1.5rem',
          fontWeight: 700,
          color: '#2a2a2a',
          pb: 1
        }}>
          {t('waitlist.title')}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3} sx={{ mt: 2 }}>
            <TextField
              label={t('waitlist.fields.name')}
              value={formData.name}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                name: e.target.value
              }))}
              fullWidth
              required
              disabled={loading}
              error={formData.name.trim() === ''}
              helperText={formData.name.trim() === '' ? t('waitlist.errors.nameRequired') : ''}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  '&:hover fieldset': {
                    borderColor: '#6478FF',
                  },
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6478FF !important',
                }
              }}
            />
            <TextField
              label={t('waitlist.fields.email')}
              value={formData.email}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                email: e.target.value
              }))}
              fullWidth
              type="email"
              required
              disabled={loading}
              error={!!formData.email && !validateEmail(formData.email)}
              helperText={formData.email && !validateEmail(formData.email) ? t('waitlist.errors.invalidEmail') : ''}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  '&:hover fieldset': {
                    borderColor: '#6478FF',
                  },
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6478FF !important',
                }
              }}
            />
            <TextField
              label={t('waitlist.fields.company')}
              value={formData.company}
              onChange={(e) => setFormData(prev => ({
                ...prev,
                company: e.target.value
              }))}
              fullWidth
              disabled={loading}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  '&:hover fieldset': {
                    borderColor: '#6478FF',
                  },
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#6478FF !important',
                }
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.consent}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    consent: e.target.checked
                  }))}
                  disabled={loading}
                  sx={{
                    color: '#6478FF',
                    '&.Mui-checked': {
                      color: '#6478FF',
                    },
                  }}
                />
              }
              label={
                <Typography variant="body2" sx={{ color: '#666' }}>
                  {t('waitlist.consent')}
                </Typography>
              }
            />
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ReCAPTCHA
                sitekey="6LeMQJgqAAAAAGXgq_uF5yxeVJZJ3ZxrKyDVnTfw"
                onChange={handleRecaptchaChange}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ 
          padding: '20px',
          gap: 2,
          justifyContent: 'center'
        }}>
          <Button 
            onClick={() => setShowWaitlistForm(false)}
            disabled={loading}
            sx={{
              borderRadius: '12px',
              padding: '8px 24px',
              textTransform: 'none',
              fontWeight: 600,
              color: '#666',
              '&:hover': {
                backgroundColor: 'rgba(100, 120, 255, 0.1)',
              }
            }}
          >
            {t('common.cancel')}
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={loading || !recaptchaValue}
            sx={{
              borderRadius: '12px',
              padding: '8px 24px',
              textTransform: 'none',
              fontWeight: 600,
              background: 'linear-gradient(45deg, #6478FF 30%, #8763FF 90%)',
              boxShadow: '0 3px 5px 2px rgba(100, 120, 255, .3)',
              '&:hover': {
                background: 'linear-gradient(45deg, #5367EE 30%, #7652EE 90%)',
              },
              '&:disabled': {
                background: '#ccc',
              }
            }}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              t('waitlist.submit')
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const UserProfile: React.FC = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [showPasswordDialog, setShowPasswordDialog] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationType, setNotificationType] = useState<'success' | 'error'>('success');

  const userData: UserProfile = {
    id: 'USR-001',
    name: 'Alexander Smith',
    email: 'alex.smith@example.com',
    phone: '+1 234 567 8900',
    avatar: '/path/to/avatar.jpg',
    status: 'active',
    memberSince: '2022-06-15',
    verified: true,
    preferences: {
      language: 'English',
      currency: 'USD',
      timezone: 'UTC-5',
      notifications: {
        email: true,
        push: true,
        sms: false,
      },
      newsletter: true,
    },
    stats: {
      totalTrips: 12,
      totalPets: 2,
      countriesVisited: 5,
      totalSpent: '$2,450',
    },
    pets: [
      {
        id: 'PET-001',
        name: 'Max',
        type: 'dog',
        avatar: '/path/to/pet1.jpg',
        age: 3,
        lastCheckup: '2024-01-15',
      },
      {
        id: 'PET-002',
        name: 'Luna',
        type: 'cat',
        avatar: '/path/to/pet2.jpg',
        age: 5,
        lastCheckup: '2024-01-20',
      },
    ],
    travelHistory: [
      {
        id: 'TRP-001',
        date: '2024-03-15',
        destination: 'Paris → London',
        pet: 'Max',
        status: 'upcoming',
      },
      {
        id: 'TRP-002',
        date: '2023-12-20',
        destination: 'New York → Miami',
        pet: 'Luna',
        status: 'completed',
      },
    ],
    paymentMethods: [
      {
        id: 'PAY-001',
        type: 'card',
        name: 'Visa Classic',
        last4: '4567',
        expiry: '12/25',
        primary: true,
      },
      {
        id: 'PAY-002',
        type: 'paypal',
        name: 'PayPal Account',
        primary: false,
      },
    ],
    documents: [
      {
        id: 'DOC-001',
        type: 'passport',
        name: 'International Passport',
        status: 'valid',
        expiry: '2028-01-15',
      },
      {
        id: 'DOC-002',
        type: 'id',
        name: 'National ID',
        status: 'valid',
        expiry: '2030-05-20',
      },
    ],
    socialAccounts: {
      google: 'alex.smith@gmail.com',
      facebook: 'alex.smith',
    },
    loyalty: {
      tier: 'gold',
      points: 1250,
      nextTier: {
        name: 'platinum',
        pointsNeeded: 750,
      },
    },
  };

  const [editedProfile, setEditedProfile] = useState(userData);

  const handleSaveProfile = () => {
    setShowNotification(true);
    setNotificationMessage(t('userProfile.messages.profileUpdated'));
    setNotificationType('success');
    setEditMode(false);
    setTimeout(() => setShowNotification(false), 3000);
  };

  const handlePasswordChange = () => {
    setShowPasswordDialog(false);
    setShowNotification(true);
    setNotificationMessage(t('userProfile.messages.passwordChanged'));
    setNotificationType('success');
    setTimeout(() => setShowNotification(false), 3000);
  };

  return (
    <Box sx={{ backgroundColor: '#f5f5f7', minHeight: '100vh', pb: 8 }}>
      <DemoHeader />
      <Container maxWidth="lg" sx={{ pt: '80px' }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Hero Section */}
          <Box
            sx={{
              position: 'relative',
              height: '250px',
              borderRadius: '30px',
              overflow: 'hidden',
              mb: 4,
              mt: 4,
              background: 'linear-gradient(45deg, #6478FF 30%, #8763FF 90%)',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                padding: { xs: '20px', md: '40px' },
                background: 'linear-gradient(to right, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.2) 70%, rgba(0,0,0,0) 100%)',
              }}
            >
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: { xs: 2, md: 3 },
                flexDirection: { xs: 'column', md: 'row' },
                textAlign: { xs: 'center', md: 'left' },
                width: '100%'
              }}>
                <ProfileAvatar
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot"
                >
                  <Avatar
                    sx={{
                      width: { xs: 100, md: 120 },
                      height: { xs: 100, md: 120 },
                      border: '4px solid white',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
                    }}
                    src={userData.avatar}
                  >
                    <Person sx={{ fontSize: { xs: 50, md: 60 } }} />
                  </Avatar>
                </ProfileAvatar>
                <Box>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1,
                    justifyContent: { xs: 'center', md: 'flex-start' }
                  }}>
                    <Typography 
                      variant="h4" 
                      sx={{ 
                        color: 'white', 
                        fontWeight: 600,
                        fontSize: { xs: '1.5rem', md: '2.125rem' }
                      }}
                    >
                      {userData.name}
                    </Typography>
                    {userData.verified && (
                      <Tooltip title={t('userProfile.verifiedAccount')}>
                        <VerifiedUser sx={{ 
                          color: '#4CAF50',
                          fontSize: { xs: '1.2rem', md: '1.5rem' }
                        }} />
                      </Tooltip>
                    )}
                  </Box>
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      color: 'rgba(255,255,255,0.9)', 
                      mb: 1,
                      fontSize: { xs: '0.9rem', md: '1rem' }
                    }}
                  >
                    {userData.email}
                  </Typography>
                  <Box sx={{ 
                    display: 'flex', 
                    gap: 1,
                    justifyContent: { xs: 'center', md: 'flex-start' }
                  }}>
                    <Chip
                      size="small"
                      icon={<AccessTime sx={{ color: '#6478FF !important' }} />}
                      label={t('userProfile.memberSince', { year: new Date(userData.memberSince).getFullYear() })}
                      sx={{ 
                        bgcolor: 'white',
                        fontSize: { xs: '0.7rem', md: '0.8rem' }
                      }}
                    />
                    <Chip
                      size="small"
                      icon={<Loyalty sx={{ color: '#FFA726 !important' }} />}
                      label={t('userProfile.loyaltyTier', { tier: userData.loyalty.tier.toUpperCase() })}
                      sx={{ 
                        bgcolor: 'white',
                        fontSize: { xs: '0.7rem', md: '0.8rem' }
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {/* Stats Cards */}
          <Grid container spacing={3} sx={{ mb: 4 }}>
            {[
              { icon: <FlightTakeoff />, value: userData.stats.totalTrips, label: t('userProfile.stats.trips') },
              { icon: <Pets />, value: userData.stats.totalPets, label: t('userProfile.stats.pets') },
              { icon: <LocationOn />, value: userData.stats.countriesVisited, label: t('userProfile.stats.countries') },
              { icon: <Payment />, value: userData.stats.totalSpent, label: t('userProfile.stats.spent') },
            ].map((stat, index) => (
              <Grid item xs={6} sm={6} md={3} key={index}>
                <StyledCard>
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Avatar
                      sx={{
                        bgcolor: 'transparent',
                        mb: 1,
                        mx: 'auto',
                        width: 48,
                        height: 48,
                        '& .MuiSvgIcon-root': {
                          fontSize: 32,
                          color: '#6478FF',
                        },
                      }}
                    >
                      {stat.icon}
                    </Avatar>
                    <Typography 
                      variant="h4" 
                      sx={{ 
                        fontWeight: 600, 
                        mb: 1,
                        whiteSpace: 'nowrap',
                        fontSize: {
                          xs: 'clamp(20px, 4vw, 24px)',
                          sm: '24px'
                        },
                      }}
                    >
                      {stat.value}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      color="text.secondary"
                      sx={{
                        fontSize: {
                          xs: '12px',
                          sm: '14px'
                        }
                      }}
                    >
                      {stat.label}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
          </Grid>

          {/* Main Content Tabs */}
          <Box sx={{ mb: 3 }}>
            <Tabs
              value={activeTab}
              onChange={(e, newValue) => setActiveTab(newValue)}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                '& .MuiTab-root': {
                  minWidth: { xs: '70px', md: '120px' },
                  padding: { xs: '6px 6px', md: '12px 16px' },
                  fontSize: { xs: '0.7rem', md: '0.875rem' },
                  fontWeight: 600,
                },
                '& .MuiTabs-scrollButtons': {
                  width: { xs: '20px', md: '40px' },
                },
                '& .Mui-selected': {
                  color: '#6478FF',
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: '#6478FF',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: { xs: '1.2rem', md: '1.5rem' },
                },
              }}
            >
              <Tab 
                icon={<AccountBox />} 
                label={t('userProfile.tabs.main')}
                sx={{
                  '& .MuiTab-iconWrapper': {
                    marginBottom: { xs: '4px', md: '8px' },
                  }
                }}
              />
              <Tab 
                icon={<Pets />} 
                label={t('userProfile.tabs.pets')}
                sx={{
                  '& .MuiTab-iconWrapper': {
                    marginBottom: { xs: '4px', md: '8px' },
                  }
                }}
              />
              <Tab 
                icon={<History />} 
                label={t('userProfile.tabs.history')}
                sx={{
                  '& .MuiTab-iconWrapper': {
                    marginBottom: { xs: '4px', md: '8px' },
                  }
                }}
              />
              <Tab 
                icon={<Payment />} 
                label={t('userProfile.tabs.payments')}
                sx={{
                  '& .MuiTab-iconWrapper': {
                    marginBottom: { xs: '4px', md: '8px' },
                  }
                }}
              />
              <Tab 
                icon={<Settings />} 
                label={t('userProfile.tabs.settings')}
                sx={{
                  '& .MuiTab-iconWrapper': {
                    marginBottom: { xs: '4px', md: '8px' },
                  }
                }}
              />
            </Tabs>
          </Box>

          {/* Tab Panels */}
          <Box sx={{ mb: 4 }}>
            {activeTab === 0 && (
              <Grid container spacing={3}>
                {/* Personal Information */}
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                          {t('userProfile.personalInfo')}
                        </Typography>
                        <IconButton onClick={() => setEditMode(!editMode)}>
                          <Edit />
                        </IconButton>
                      </Box>
                      {editMode ? (
                        <Stack spacing={2}>
                          <TextField
                            label={t('userProfile.fields.name')}
                            value={editedProfile.name}
                            onChange={(e) => setEditedProfile({ ...editedProfile, name: e.target.value })}
                            fullWidth
                          />
                          <TextField
                            label={t('userProfile.fields.email')}
                            value={editedProfile.email}
                            onChange={(e) => setEditedProfile({ ...editedProfile, email: e.target.value })}
                            fullWidth
                          />
                          <TextField
                            label={t('userProfile.fields.phone')}
                            value={editedProfile.phone}
                            onChange={(e) => setEditedProfile({ ...editedProfile, phone: e.target.value })}
                            fullWidth
                          />
                          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                            <Button
                              variant="outlined"
                              onClick={() => setEditMode(false)}
                            >
                              {t('common.cancel')}
                            </Button>
                            <Button
                              variant="contained"
                              onClick={handleSaveProfile}
                            >
                              {t('common.save')}
                            </Button>
                          </Box>
                        </Stack>
                      ) : (
                        <List>
                          <ListItem>
                            <ListItemIcon>
                              <Person />
                            </ListItemIcon>
                            <ListItemText
                              primary={t('userProfile.fields.name')}
                              secondary={userData.name}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <Email sx={{ color: '#6478FF' }} />
                            </ListItemIcon>
                            <ListItemText
                              primary={t('userProfile.fields.email')}
                              secondary={userData.email}
                            />
                          </ListItem>
                          <ListItem>
                            <ListItemIcon>
                              <Phone sx={{ color: '#6478FF' }} />
                            </ListItemIcon>
                            <ListItemText
                              primary={t('userProfile.fields.phone')}
                              secondary={userData.phone}
                            />
                          </ListItem>
                        </List>
                      )}
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Loyalty Program */}
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <CardContent>
                      <Typography variant="h6" sx={{ fontWeight: 600, mb: 3 }}>
                        {t('userProfile.loyaltyProgram')}
                      </Typography>
                      <Box sx={{ position: 'relative', mb: 3 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                          <Typography variant="body1" sx={{ flexGrow: 1 }}>
                            {t('userProfile.nextTierProgress')}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {userData.loyalty.points}/{userData.loyalty.points + userData.loyalty.nextTier.pointsNeeded}
                          </Typography>
                        </Box>
                        <LinearProgress
                          variant="determinate"
                          value={(userData.loyalty.points / (userData.loyalty.points + userData.loyalty.nextTier.pointsNeeded)) * 100}
                          sx={{
                            height: 8,
                            borderRadius: 4,
                            bgcolor: 'rgba(100, 120, 255, 0.2)',
                            '& .MuiLinearProgress-bar': {
                              bgcolor: '#6478FF',
                              borderRadius: 4,
                            },
                          }}
                        />
                      </Box>
                      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
                        <Chip
                          icon={<Loyalty />}
                          label={`${userData.loyalty.points} ${t('userProfile.points')}`}
                          sx={{ bgcolor: 'rgba(100, 120, 255, 0.1)' }}
                        />
                        <Chip
                          icon={<LocalOffer />}
                          label={t('userProfile.toNextTier', { tier: userData.loyalty.nextTier.name })}
                          sx={{ bgcolor: 'rgba(255, 167, 38, 0.1)' }}
                        />
                      </Box>
                      <List dense>
                        <ListItem>
                          <ListItemIcon>
                            <CheckCircle sx={{ color: '#4CAF50' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={t('userProfile.availableDiscounts.booking')}
                            secondary={t('userProfile.availableDiscounts.validUntil', { date: '31.12.2024' })}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <CheckCircle sx={{ color: '#4CAF50' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={t('userProfile.availableDiscounts.prioritySupport')}
                            secondary={t('userProfile.availableDiscounts.currentTier')}
                          />
                        </ListItem>
                      </List>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Connected Accounts */}
                <Grid item xs={12}>
                  <StyledCard>
                    <CardContent>
                      <Typography variant="h6" sx={{ fontWeight: 600, mb: 3 }}>
                        {t('userProfile.connectedAccounts')}
                      </Typography>
                      <Grid container spacing={2}>
                        {[
                          { icon: <Google />, name: 'Google', connected: true },
                          { icon: <Facebook />, name: 'Facebook', connected: true },
                          { icon: <Apple />, name: 'Apple', connected: false },
                          { icon: <Telegram />, name: 'Telegram', connected: false },
                        ].map((account, index) => (
                          <Grid item xs={12} sm={6} md={3} key={index}>
                            <Box
                              sx={{
                                p: 2,
                                border: '1px solid',
                                borderColor: account.connected ? '#4CAF50' : 'divider',
                                borderRadius: 2,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                              }}
                            >
                              <Avatar sx={{ bgcolor: 'transparent' }}>
                                {React.cloneElement(account.icon, {
                                  sx: { color: account.connected ? '#4CAF50' : 'text.secondary' },
                                })}
                              </Avatar>
                              <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="body1">
                                  {account.name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  {account.connected ? 
                                    t('userProfile.accountStatus.connected') : 
                                    t('userProfile.accountStatus.notConnected')
                                  }
                                </Typography>
                              </Box>
                              <Button
                                variant={account.connected ? "outlined" : "contained"}
                                size="small"
                                color={account.connected ? "error" : "primary"}
                              >
                                {account.connected ? 
                                  t('userProfile.actions.disconnect') : 
                                  t('userProfile.actions.connect')
                                }
                              </Button>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                  </StyledCard>
                </Grid>
              </Grid>
            )}

            {activeTab === 1 && (
              <Grid container spacing={3}>
                {userData.pets.map((pet) => (
                  <Grid item xs={12} sm={6} md={4} key={pet.id}>
                    <StyledCard>
                      <CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <Avatar
                            src={pet.avatar}
                            sx={{ width: 60, height: 60, mr: 2 }}
                          >
                            <Pets />
                          </Avatar>
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography variant="h6" sx={{ fontWeight: 600 }}>
                              {pet.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {`${pet.type}, ${pet.age} ${t('common.years')}`}
                            </Typography>
                          </Box>
                          <IconButton>
                            <Edit />
                          </IconButton>
                        </Box>
                        <Divider sx={{ my: 2 }} />
                        <List dense>
                          <ListItem>
                            <ListItemIcon>
                              <AccessTime sx={{ color: '#6478FF' }} />
                            </ListItemIcon>
                            <ListItemText
                              primary={t('userProfile.petProfile.lastCheckup')}
                              secondary={new Date(pet.lastCheckup).toLocaleDateString()}
                            />
                          </ListItem>
                        </List>
                        <Button
                          variant="outlined"
                          fullWidth
                          sx={{ mt: 2 }}
                          onClick={() => {/* Navigate to pet profile */}}
                        >
                          {t('userProfile.petProfile.openProfile')}
                        </Button>
                      </CardContent>
                    </StyledCard>
                  </Grid>
                ))}
                <Grid item xs={12} sm={6} md={4}>
                  <StyledCard
                    sx={{
                      height: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      bgcolor: 'rgba(100, 120, 255, 0.1)',
                      cursor: 'pointer',
                    }}
                  >
                    <CardContent sx={{ textAlign: 'center' }}>
                      <Avatar
                        sx={{
                          bgcolor: '#6478FF',
                          width: 60,
                          height: 60,
                          mb: 2,
                          mx: 'auto',
                        }}
                      >
                        <Add />
                      </Avatar>
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {t('userProfile.petProfile.addPet')}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {t('userProfile.petProfile.addPetDescription')}
                      </Typography>
                    </CardContent>
                  </StyledCard>
                </Grid>
              </Grid>
            )}

            {activeTab === 2 && (
              <Grid container spacing={3}>
                {userData.travelHistory.map((trip) => (
                  <Grid item xs={12} key={trip.id}>
                    <StyledCard>
                      <CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Avatar sx={{ bgcolor: 'rgba(100, 120, 255, 0.1)' }}>
                              <FlightTakeoff sx={{ color: '#6478FF' }} />
                            </Avatar>
                            <Box>
                              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                {trip.destination}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {new Date(trip.date).toLocaleDateString()} • With pet: {trip.pet}
                              </Typography>
                            </Box>
                          </Box>
                          <Chip
                            label={t(`userProfile.travelHistory.status.${trip.status}`)}
                            color={getStatusColor(trip.status)}
                          />
                        </Box>
                      </CardContent>
                    </StyledCard>
                  </Grid>
                ))}
              </Grid>
            )}

            {activeTab === 3 && (
              <Grid container spacing={3}>
                {/* Payment Methods */}
                <Grid item xs={12} md={8}>
                  <StyledCard>
                    <CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                          {t('userProfile.paymentMethods.title')}
                        </Typography>
                        <Button
                          variant="contained"
                          startIcon={<Add />}
                          sx={{
                            background: 'linear-gradient(45deg, #6478FF 30%, #8763FF 90%)',
                          }}
                        >
                          {t('userProfile.addCard')}
                        </Button>
                      </Box>
                      <Stack spacing={2}>
                        {userData.paymentMethods.map((method) => (
                          <Box
                            key={method.id}
                            sx={{
                              p: 2,
                              border: '1px solid',
                              borderColor: method.primary ? '#6478FF' : 'divider',
                              borderRadius: 2,
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <Avatar sx={{ bgcolor: 'rgba(100, 120, 255, 0.1)' }}>
                              {method.type === 'card' ? <CreditCard sx={{ color: '#6478FF' }} /> :
                               <Wallet sx={{ color: '#6478FF' }} />}
                            </Avatar>
                            <Box sx={{ flexGrow: 1 }}>
                              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                                {method.name}
                              </Typography>
                              {method.type === 'card' && (
                                <Typography variant="body2" color="text.secondary">
                                  •••• {t('userProfile.cardDetails', { last4: method.last4, expiry: method.expiry })}
                                </Typography>
                              )}
                            </Box>
                            {method.primary && (
                              <Chip
                                label={t('userProfile.primary')}
                                size="small"
                                sx={{ bgcolor: 'rgba(100, 120, 255, 0.1)' }}
                              />
                            )}
                            <IconButton>
                              <MoreVert />
                            </IconButton>
                          </Box>
                        ))}
                      </Stack>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Billing History */}
                <Grid item xs={12} md={4}>
                  <StyledCard>
                    <CardContent>
                      <Typography variant="h6" sx={{ fontWeight: 600, mb: 3 }}>
                        {t('userProfile.billingHistory')}
                      </Typography>
                      <List>
                        {[
                          { date: '2024-02-15', amount: '$15,000', desc: 'Flight booking' },
                          { date: '2024-01-20', amount: '$8,500', desc: 'Pet transportation' },
                          { date: '2023-12-10', amount: '$12,000', desc: 'Flight booking' },
                        ].map((payment, index) => (
                          <ListItem key={index}>
                            <ListItemText
                              primary={payment.desc}
                              secondary={new Date(payment.date).toLocaleDateString()}
                            />
                            <Typography variant="body1" sx={{ fontWeight: 500 }}>
                              {payment.amount}
                            </Typography>
                          </ListItem>
                        ))}
                      </List>
                      <Button
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2 }}
                      >
                        {t('userProfile.showAll')}
                      </Button>
                    </CardContent>
                  </StyledCard>
                </Grid>
              </Grid>
            )}

            {activeTab === 4 && (
              <Grid container spacing={3}>
                {/* Preferences */}
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <CardContent>
                      <Typography variant="h6" sx={{ fontWeight: 600, mb: 3 }}>
                        {t('userProfile.preferences.title')}
                      </Typography>
                      <List>
                        <ListItem>
                          <ListItemIcon>
                            <Language sx={{ color: '#6478FF' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={t('userProfile.language')}
                            secondary={userData.preferences.language}
                          />
                          <IconButton>
                            <Edit />
                          </IconButton>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <AttachMoney sx={{ color: '#6478FF' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={t('userProfile.currency')}
                            secondary={userData.preferences.currency}
                          />
                          <IconButton>
                            <Edit />
                          </IconButton>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <AccessTime sx={{ color: '#6478FF' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={t('userProfile.timezone')}
                            secondary={userData.preferences.timezone}
                          />
                          <IconButton>
                            <Edit />
                          </IconButton>
                        </ListItem>
                      </List>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Notifications */}
                <Grid item xs={12} md={6}>
                  <StyledCard>
                    <CardContent>
                      <Typography variant="h6" sx={{ fontWeight: 600, mb: 3 }}>
                        {t('userProfile.notifications.title')}
                      </Typography>
                      <List>
                        <ListItem>
                          <ListItemIcon>
                            <Email sx={{ color: '#6478FF' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={t('userProfile.emailNotifications')}
                            secondary={t('userProfile.newsAndUpdates')}
                          />
                          <Switch
                            checked={userData.preferences.notifications.email}
                            onChange={() => {}}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <NotificationsActive sx={{ color: '#6478FF' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={t('userProfile.pushNotifications')}
                            secondary={t('userProfile.instantNotifications')}
                          />
                          <Switch
                            checked={userData.preferences.notifications.push}
                            onChange={() => {}}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Message sx={{ color: '#6478FF' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={t('userProfile.smsNotifications')}
                            secondary={t('userProfile.importantMessages')}
                          />
                          <Switch
                            checked={userData.preferences.notifications.sms}
                            onChange={() => {}}
                          />
                        </ListItem>
                      </List>
                    </CardContent>
                  </StyledCard>
                </Grid>

                {/* Security */}
                <Grid item xs={12}>
                  <StyledCard>
                    <CardContent>
                      <Typography variant="h6" sx={{ fontWeight: 600, mb: 3 }}>
                        {t('userProfile.security.title')}
                      </Typography>
                      <List>
                        <ListItem>
                          <ListItemIcon>
                            <VpnKey sx={{ color: '#6478FF' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={t('userProfile.changePassword')}
                            secondary={t('userProfile.lastChange', { time: '3 months' })}
                          />
                          <Button
                            variant="outlined"
                            onClick={() => setShowPasswordDialog(true)}
                          >
                            {t('userProfile.change')}
                          </Button>
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Security sx={{ color: '#6478FF' }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={t('userProfile.twoFactorAuthentication')}
                            secondary={t('userProfile.additionalSecurity')}
                          />
                          <Switch
                            checked={false}
                            onChange={() => {}}
                          />
                        </ListItem>
                      </List>
                    </CardContent>
                  </StyledCard>
                </Grid>
              </Grid>
            )}
          </Box>
        </motion.div>
      </Container>

      {/* Password Change Dialog */}
      <Dialog
        open={showPasswordDialog}
        onClose={() => setShowPasswordDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>{t('userProfile.security.changePassword.title')}</DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <TextField
              label={t('userProfile.security.changePassword.currentPassword')}
              type="password"
              fullWidth
            />
            <TextField
              label={t('userProfile.security.changePassword.newPassword')}
              type="password"
              fullWidth
            />
            <TextField
              label={t('userProfile.security.changePassword.confirmPassword')}
              type="password"
              fullWidth
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowPasswordDialog(false)}>
            {t('common.cancel')}
          </Button>
          <Button
            onClick={handlePasswordChange}
            variant="contained"
          >
            {t('common.save')}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Notification Snackbar */}
      <Snackbar
        open={showNotification}
        autoHideDuration={3000}
        onClose={() => setShowNotification(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setShowNotification(false)}
          severity={notificationType}
        >
          {notificationMessage}
        </Alert>
      </Snackbar>

      {/* Demo Mode Chip */}
      <Box sx={{ position: 'fixed', top: 20, right: 20, zIndex: 1000 }}>
        <Chip
          label={t('userProfile.demoMode')}
          color="primary"
          sx={{
            background: 'linear-gradient(45deg, #6478FF 30%, #8763FF 90%)',
            color: 'white',
            fontWeight: 600,
            boxShadow: '0 3px 5px 2px rgba(100, 120, 255, .3)',
          }}
        />
      </Box>
    </Box>
  );
};

export default UserProfile;
