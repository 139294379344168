import React from 'react';
import { Box, Typography } from '@mui/material';

const PetServices: React.FC = () => {
  return (
    <Box>
      <Typography variant="h5">Pet Services</Typography>
      {/* Контент сервисов для животных */}
    </Box>
  );
};

export default PetServices; 