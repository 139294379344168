import React, { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';

// Интерфейс пропсов для ErrorBoundary
interface ErrorBoundaryProps extends WithTranslation {
  children: ReactNode;
}

// Интерфейс состояния для ErrorBoundary
interface ErrorBoundaryState {
  hasError: boolean;
}

// Компонент ErrorBoundary для отлова ошибок
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  // Метод для обновления состояния при возникновении ошибки
  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  // Метод для логирования ошибки и информации о ней
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Caught an error:", error, errorInfo);
  }

  // Метод рендеринга
  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Typography variant="h5" color="error">
            {this.props.t('errorBoundary.message')}
          </Typography>
        </Container>
      );
    }

    return this.props.children;
  }
}

// Экспорт компонента с поддержкой интернационализации
export default withTranslation()(ErrorBoundary);
